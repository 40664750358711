<template>
  <div class="contact">
    <div class="container">
      <div class="title">CONTACT US 联系我们</div>
      <div class="detail">请通过下方联系方式或地址与我们取得联系</div>
      <!-- <div class="img"><img src="../assets/images/map.png" /></div> -->
      <div id='map-container'>
        <el-amap ref="map" vid="amapDemo" :center="center" expandZoomRange="true" :zoom="18" :pitch="66">
          <el-amap-marker :position="center" icon="//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png">
          </el-amap-marker>
        </el-amap>
      </div>
    </div>
  </div>
</template>
<script>

import { AMapManager } from "vue-amap";
let amapManager = new AMapManager();
export default {
  name: "Contact",
  data() {
    return {
      center: [121.554389, 29.834271],
      plugins: [
        {
          enableHighAccuracy: true, //是否使用高精度定位，默认:true
          timeout: 100, //超过10秒后停止定位，默认：无穷大
          maximumAge: 0, //定位结果缓存0毫秒，默认：0
          convert: true, //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
          showButton: true, //显示定位按钮，默认：true
          buttonPosition: "LB", //定位按钮停靠位置，默认：'LB'，左下角
          showMarker: true, //定位成功后在定位到的位置显示点标记，默认：true
          showCircle: true, //定位成功后用圆圈表示定位精度范围，默认：true
          panToLocation: false, //定位成功后将定位到的位置作为地图中心点，默认：true
          zoomToAccuracy: true, //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：f
          extensions: "all",
          //地图定位按钮
          pName: "Geolocation",
          init(o) {
            // o 是高德地图定位插件实例
            o.getCurrentPosition((status, result) => {
              console.log(result);
              if (result && result.position) {
                self.lng = result.position.lng;
                self.lat = result.position.lat;
                self.center = [self.lng, self.lat];
                self.loaded = true;
                self.$nextTick();
              }
            });
          },
        },
        {
          //地图工具条
          pName: "ToolBar",
          init(o) { },
        },
        {
          //左下角缩略图插件 比例尺
          pName: "Scale",
          init(o) { },
        },
      ],
    }
  },

}
</script>

<style scoped lang="less">
@media screen and (min-width:768px) {
  .contact {
    width: 100%;
    height: 700px;
    background-color: #19243a;
  }

  .container {
    width: 920px;
    height: 700px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    #map-container{
      width: 900px;
      height: 600px;
    }
  }

  .title {
    width: 920px;
    text-align: left;
    font-size: 20px;
    color: #313a4b;
    border-bottom: 2px solid #313a4b;
  }

  .detail {
    width: 920px;
    text-align: left;
    font-size: 16px;
    color: #e6e6e6;
  }

  .img img {
    width: 920px;
    height: auto;
  }
}

@media screen and (max-width:768px) {
  .contact {
    width: 100vw;
    background-color: #19243a;
  }

  .container {
    width: 100vw;
    // height: 700px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    #map-container{
      width: 90vw;
      height: 50vh;
    }
  }

  .title {
    width: 100vw;
    text-align: left;
    font-size: 1.3rem;
    color: #313a4b;
    border-bottom: 0.2vh solid #313a4b;
  }

  .detail {
    width: 100vw;
    text-align: left;
    font-size: 0.6rem;
    font-weight: 600;
    color: #e6e6e6;
    height: 5vh;
    line-height: 5vh;
    margin-left: 5vw;
  }

  .img1 {
    width: 100vw !important;
    height: 100% !important;

    img {
      width: 100% !important;
      height: 100% !important;
      display: block;
      //  vertical-align: middle;
    }
  }
}
</style>