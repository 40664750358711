<template>
    <div class="container">
        <div class="main-body">
            <div class="container-item" v-for="(i, d) in dataAll" :key="d">
                <div class="title">{{ i.maintitle }}</div>
                <div class="item-group">
                    <div class="item" v-for="(item, index) in i.dataList" :key="index">
                        <div class="item-title">{{ item.title }}</div>
                        <div class="item-pic"><a :href="item.src"><img :src="item.pic"></a></div>
                        <div class="item-tips">{{ item.tips }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "Learn",
    data() {
        return {
            dataAll: [
                {
                    maintitle: '鸿蒙开发基础',
                    dataList: [{
                        title: '什么是HarmonyOS',
                        pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/%E4%BB%80%E4%B9%88%E6%98%AFHarmonyOS.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                        tips: '一分钟带你了解什么是HarmonyOs。欢迎加入HarmonyOS生态。',
                        src: 'https://developer.huawei.com/consumer/cn/training/course/video/101605600652198120'
                    },
                    {
                        title: 'HarmonyOS系统架构',
                        pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/HarmonyOS%E7%B3%BB%E7%BB%9F%E6%9E%B6%E6%9E%84.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                        tips: '三分钟时间，看懂HarmonyOS系统架构，以及FA/PA原理。',
                        src: 'https://developer.huawei.com/consumer/cn/training/course/video/101605600905237027'
                    },
                    {
                        title: 'OpenHarmony开源项目介绍',
                        pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/HarmonyOS%E6%99%BA%E8%83%BD%E7%A1%AC%E4%BB%B6%E5%BC%80%E5%8F%91-OpenHarmony%E5%BC%80%E6%BA%90%E9%A1%B9%E7%9B%AE%E4%BB%8B%E7%BB%8D.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                        tips: 'OpenHarmony开源项目具体内容介绍，学习资源及办法介绍，参与开源的具体操作办法。',
                        src: 'https://developer.huawei.com/consumer/cn/training/course/video/101603334677358039'
                    }]

                },
                {
                    maintitle: '开发工具使用教程',
                    dataList: [
                        {
                            title: '鸿蒙应用开发平台和工具介绍',
                            pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/HarmonyOS%E5%BA%94%E7%94%A8%E5%BC%80%E5%8F%91-%E5%BA%94%E7%94%A8%E5%BC%80%E5%8F%91%E5%B9%B3%E5%8F%B0%E5%92%8C%E5%B7%A5%E5%85%B7%E6%80%BB%E4%BD%93%E4%BB%8B%E7%BB%8D.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                            tips: 'HUAWEI DevEco Studio 和DevEco Device Tool 等应用开发平台和工具介绍和使用。',
                            src: 'https://developer.huawei.com/consumer/cn/training/course/video/101603095327940006'
                        },
                        {
                            title: 'DevEco Studio分布式跨设备应用开发实践',
                            pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/HarmonyOS%E5%BA%94%E7%94%A8%E5%BC%80%E5%8F%91-DevEco%20Studio%E5%88%86%E5%B8%83%E5%BC%8F%E8%B7%A8%E8%AE%BE%E5%A4%87%E5%BA%94%E7%94%A8%E5%BC%80%E5%8F%91%E5%AE%9E%E8%B7%B5.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                            tips: '了解基于DevEco Studio如何开发分布式跨设备的HarmonyOS应用。',
                            src: 'https://developer.huawei.com/consumer/cn/training/course/video/101603095258356005'
                        },
                        {
                            title: 'DevEco Studio分布式应用调试指南',
                            pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/deveco%E8%B0%83%E8%AF%95.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                            tips: '跨语言调试，跨设备调试，变量可视化X-Ray Debugger以及分布式调用链分析-HiTrace。',
                            src: 'https://developer.huawei.com/consumer/cn/training/course/video/101603095190185018'
                        }
                    ]
                },
                {
                    maintitle: '应用开发视频教程',
                    dataList: [
                        {
                            title: 'HarmonyOS应用开发理念和设计',
                            pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/HarmonyOS%E5%BA%94%E7%94%A8%E5%BC%80%E5%8F%91-%E7%90%86%E5%BF%B5%E5%92%8C%E8%AE%BE%E8%AE%A1.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                            tips: '介绍HarmonyOS总体架构和分布式关键技术、分布式应用程序框架及应用示例。',
                            src: 'https://developer.huawei.com/consumer/cn/training/course/video/101603095794057095'
                        },
                        {
                            title: 'HarmonyOS应用程序框架揭秘',
                            pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/HarmonyOS%E5%BA%94%E7%94%A8%E5%BC%80%E5%8F%91-%E5%BA%94%E7%94%A8%E7%A8%8B%E5%BA%8F%E6%A1%86%E6%9E%B6%E6%8F%AD%E7%A7%98.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                            tips: '介绍HarmonyOS的应用程序框架的设计思路，具体组成，以及内部机制等。',
                            src: 'https://developer.huawei.com/consumer/cn/training/course/video/101603095683428095'
                        },
                        {
                            title: 'HarmonyOS应用开发UX体验设计',
                            pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/HarmonyOS%E5%BA%94%E7%94%A8%E5%BC%80%E5%8F%91-UX%E4%BD%93%E9%AA%8C%E8%AE%BE%E8%AE%A1.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                            tips: '介绍针对设备特点的适应性设计、针对多设备拉通的通用性设计、以及针对跨设备交互的分布式设计。',
                            src: 'https://developer.huawei.com/consumer/cn/training/course/video/101603095190185018'
                        },
                        {
                            title: 'HarmonyOS纯净应用开发',
                            pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/HarmonyOS%E5%BA%94%E7%94%A8%E5%BC%80%E5%8F%91-%E7%BA%AF%E5%87%80%E5%BA%94%E7%94%A8%E5%BC%80%E5%8F%91-0002.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                            tips: '介绍HarmonyOS纯净应用的意义、定义和理念，以及应用开发的标准，方法和工具平台。',
                            src: 'https://developer.huawei.com/consumer/cn/training/course/video/101603095020288094'
                        },
                        {
                            title: 'HarmonyOS开发模拟与仿真器',
                            pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/HarmonyOS%E5%BA%94%E7%94%A8%E5%BC%80%E5%8F%91-%E6%A8%A1%E6%8B%9F%E4%B8%8E%E4%BB%BF%E7%9C%9F%E5%99%A8.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                            tips: 'HarmonyOS平台多终端设备模拟，以及丰富的动态场景仿真介绍。',
                            src: 'https://developer.huawei.com/consumer/cn/training/course/video/101603095020288094'
                        },
                        {
                            title: '从零开发一个HarmonyOS应用',
                            pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/HarmonyOS%E5%BA%94%E7%94%A8%E5%BC%80%E5%8F%91-%E4%BB%8E%E9%9B%B6%E5%BC%80%E5%A7%8B%E5%BC%80%E5%8F%91%E4%B8%80%E4%B8%AAHarmonyOS%E5%BA%94%E7%94%A8.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                            tips: '教你如何使用创建应用布局，绑定用户交互事件，以及编译、安装，及运行等。',
                            src: 'https://developer.huawei.com/consumer/cn/training/detail/101603095745103009'
                        },
                        {
                            title: '开发超级虚拟终端的创新体验',
                            pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/%E5%BC%80%E5%8F%91%E8%B6%85%E7%BA%A7%E8%99%9A%E6%8B%9F%E7%BB%88%E7%AB%AF.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                            tips: '介绍硬件虚拟共享的理念、技术架构，阐述了硬件虚拟共享中的硬件虚拟化、硬件协同关键能力。',
                            src: 'https://developer.huawei.com/consumer/cn/training/course/video/101603095512931020'
                        },
                        {
                            title: '构建低时延高带宽的虚拟网络',
                            pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/%E6%9E%84%E5%BB%BA%E4%BD%8E%E5%BB%B6%E6%97%B6%E9%AB%98%E5%B8%A6%E5%AE%BD%E8%99%9A%E6%8B%9F%E7%BD%91%E7%BB%9C.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                            tips: '介绍软总线自动发现、全连接组网、极速传输的用户体验和背后的关键技术。',
                            src: 'https://developer.huawei.com/consumer/cn/training/course/video/101603095627829008'
                        },
                        {
                            title: 'HarmonyOS应用测试最佳实践',
                            pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/HarmonyOS%E5%BA%94%E7%94%A8%E5%BC%80%E5%8F%91-%E5%BA%94%E7%94%A8%E6%B5%8B%E8%AF%95%E6%9C%80%E4%BD%B3%E5%AE%9E%E8%B7%B5.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                            tips: 'HarmonyOS测试工具解决方案：DECC，这是一站式多语言测试框架。',
                            src: 'https://developer.huawei.com/consumer/cn/training/course/video/101603095115414017'
                        }

                    ]
                },
                {
                    maintitle: '智能硬件开发视频教程',
                    dataList:
                        [
                            {
                                title: '鸿蒙智能硬件开发理念和设计',
                                pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/HarmonyOS%E6%99%BA%E8%83%BD%E7%A1%AC%E4%BB%B6%E5%BC%80%E5%8F%91-%E7%90%86%E5%BF%B5%E5%92%8C%E8%AE%BE%E8%AE%A1.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                                tips: '介绍HarmonyOS重新定位人-设备-场景的关系带来智能全场景体验的设计理念。',
                                src: 'https://developer.huawei.com/consumer/cn/training/course/video/101603333077509026'
                            },
                            {
                                title: 'HarmonyOS智能硬件内核设计',
                                pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/HarmonyOS%E6%99%BA%E8%83%BD%E7%A1%AC%E4%BB%B6%E5%BC%80%E5%8F%91-%E5%86%85%E6%A0%B8%E8%AE%BE%E8%AE%A1.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                                tips: '通过多进程、系统调用、动态链接、全量POSIX库特性，实现类Linux的开发体验和RTOS效果。',
                                src: 'https://developer.huawei.com/consumer/cn/training/course/video/101603333688642112'
                            },
                            {
                                title: 'HarmonyOS智能硬件UX体验设计',
                                pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/HarmonyOS%E6%99%BA%E8%83%BD%E7%A1%AC%E4%BB%B6%E5%BC%80%E5%8F%91-UX%E4%BD%93%E9%AA%8C%E8%AE%BE%E8%AE%A1.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                                tips: '以碰一碰为特征的近场设备连接和控制的交互体验，打破传统IOT连接控制步骤多等待长的体验壁垒。',
                                src: 'https://developer.huawei.com/consumer/cn/training/course/video/101603334122074037'
                            },
                            {
                                title: 'HarmonyOS驱动程序开发',
                                pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/HarmonyOS%E6%99%BA%E8%83%BD%E7%A1%AC%E4%BB%B6%E5%BC%80%E5%8F%91-%E5%BC%80%E5%8F%91%E4%B8%80%E4%B8%AAHarmonyOS%E7%9A%84%E9%A9%B1%E5%8A%A8%E7%A8%8B%E5%BA%8F.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                                tips: '介绍平台的归一化平台底座、弹性化框架、组件化驱动模型、统一配置界面等架构特征。',
                                src: 'https://developer.huawei.com/consumer/cn/training/course/video/101603333814551036'
                            },
                            {
                                title: '鸿蒙智能硬件应用程序开发',
                                pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/%E6%99%BA%E8%83%BD%E7%A1%AC%E4%BB%B6%E5%BA%94%E7%94%A8%E7%A8%8B%E5%BA%8F%E5%BC%80%E5%8F%91.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                                tips: 'HarmonyOS在1+8+N多设备场景下应用程序框架所面临的主要挑战和解决策略。',
                                src: 'https://developer.huawei.com/consumer/cn/training/course/video/101603333958513028'
                            },
                            {
                                title: '从零开发一个鸿蒙智能硬件',
                                pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/HarmonyOS%E6%99%BA%E8%83%BD%E7%A1%AC%E4%BB%B6%E5%BC%80%E5%8F%91-%E4%BB%8E%E9%9B%B6%E5%BC%80%E5%8F%91%E4%B8%80%E4%B8%AAHarmonyOS%E6%99%BA%E8%83%BD%E7%A1%AC%E4%BB%B6.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                                tips: '从零开发一个HarmonyOS智能硬件，包括典型应用场景、如何开发、开发工具等。',
                                src: 'https://developer.huawei.com/consumer/cn/training/course/video/101603333549907111'
                            },
                            {
                                title: '使用HPM定制HarmonyOS软件包',
                                pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/HPM%E5%AE%9A%E5%88%B6HarmonyOS%E8%BD%AF%E4%BB%B6%E5%8C%85.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                                tips: '介绍了HPM包管理器使用场景、关键特性和价值，并提供两个案例。',
                                src: 'https://developer.huawei.com/consumer/cn/training/course/video/101603334579679029'
                            },
                            {
                                title: '体验创新及生态合作伙伴发展计划',
                                pic: 'https://img1.wsimg.com/isteam/ip/bc2bf02b-5e6a-441e-b955-a49c2535530a/%E4%BD%93%E9%AA%8C%E5%88%9B%E6%96%B0%E5%8F%8A%E7%94%9F%E6%80%81%E4%BC%99%E4%BC%B4%E5%8F%91%E5%B1%95%E8%AE%A1%E5%88%92.png/:/rs=w:388,h:194,cg:true,m/cr=w:388,h:194',
                                tips: '介绍HarmonyOS的产业定位，生态建设进展，以及后继的硬件合作伙伴发展计划。',
                                src: 'https://developer.huawei.com/consumer/cn/training/course/video/101603334270107038'
                            },

                        ]
                },

            ]
        }
    }
};
</script>
  
<style scoped lang="less">
@media screen and (min-width:768px){.container {
    width: 100%;
    /* height: 700px; */
    background-color: #19243a;
    padding-top: 0.1px;
    padding-bottom: 0.1px;
    display: flex;
    align-items: center;
    /* border: none; */
    .main-body {
        min-width: 920px;
        // width: 100%;
        height: 100%;
        margin: 20px auto;
        background-color: #19243a;

        .container-item {
            width: 920px;
            /* min-height: 400px; */
            display: block;

            .title {
                font-size: 28px;
                color: white;
                width: 920px;
                height: 50px;
                line-height: 40px;
                border-bottom: 1px solid rgb(209, 209, 209);
            }

            .item-group {
                width: 920px;
                /* height: 300px; */
                margin: 20px 0;
                display: flex;
                align-items: center;
                /* justify-content: space-between; */
                flex-wrap: wrap;
                .item:not(:nth-child(3n)) {
                        margin-right: 4%;
                    }
                .item {
                    width: 30%;

                    /* height: 300px; */

                    .item-title {
                        color: white;
                        font-size: 20px;
                        font-weight: 400;
                        margin: 20px 0;
                        height: 40px;
                    }

                    .item-pic img {
                        width: 300px;
                    }

                    .item-tips {
                        font-size: 14px;
                        font-weight: 200;
                        color: rgb(173, 167, 167);
                        margin: 10px 0;
                        height: 80px;
                    }
                }
            }
        }
    }
}}
@media screen and (max-width:768px){
.container {
    width: 100vw !important;
    background-color: #19243a;
    padding-top: 0.1px;
    padding-bottom: 0.1px;
    display: block;
    align-items: center;
    .main-body {
        width: 96vw !important;

        margin: 1vh auto;
        background-color: #19243a;

        .container-item {
            width: 96vw !important;
            display: block;
            .title {
                font-size: 1.2rem;
                color: white;
                width: 96vw !important;
                font-weight: 800;
                height: 7vh;
                line-height: 7vh;
                border-bottom: 0.1vh solid rgb(209, 209, 209);
            }

            .item-group {
                width: 96vw !important;
                margin: 2vh 0;
                display: flex;
                align-items: center;
                /* justify-content: space-between; */
                flex-wrap: wrap;
                .item:not(:nth-child(3n)) {
                        margin-right: 4%;
                    }
                .item {
                    width: 30%;
                    /* height: 300px; */
                    .item-title {
                        color: white;
                        font-size: 0.8rem;
                        font-weight: 400;
                        margin: 0.8vh 0;
                        height: 6vh;
                    }

                    .item-pic img {
                        width: 30vw;
                    }

                    .item-tips {
                        font-size: 0.6rem;
                        font-weight: 200;
                        color: rgb(173, 167, 167);
                        margin: 0.5vh 0;
                        height: 15vh;
                    }
                }
            }
        }
    }
}}

</style>