<template>
  <div class="body">
    <div class="img1">
      <img :src="require(`../assets/images/${currentimg}`)" />
      <div class="page">
        <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" small background
          layout="prev, pager, next" :total="20">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "List",
  data() {
    return {
      currentPage: 1,
      length: 2,
      currentimg: "list.jpg",
      img: [
        "list.jpg",
        "list1.jpg"
      ]
    }
  },
  methods: {
    handleCurrentChange(index) {
      this.currentimg = this.img[index - 1]
    }
  }
};
</script>

<style scoped lang="less">
@media screen and (min-width:768px) {
  .img1 {
    position: relative;
  }

  .img1 img {
    display: block;
    width: 100%;
    height: auto;
    min-width: 1200px !important;
  }

  .page {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
}


@media screen and (max-width:768px) {
  .img1 {
    position: relative;
    width: 100vw !important;
    height: 100% !important;

    img {
      width: 100% !important;
      height: 100% !important;
      display: block;
    }
  }

  .page {
    position: absolute;
    bottom: 0.1vh;
    left: 50%;
    transform: translateX(-50%);
  }

}
</style>