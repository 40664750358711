<template>
  <div class="container">
    <div class="tabtopout">
      <div class="tabtop">
        <div class="phone">
          <div class="menu" @click="simuActive">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="logo"><img src="../assets/images/logo2.png" /></div>
        <div class="titlecontainer">
          <router-link class="titlelink title" to="/home">首页</router-link>
          <router-link class="titlelink title" to="/product">产品中心</router-link>
          <router-link class="titlelink title" to="/learn">鸿蒙学堂</router-link>
          <router-link class="titlelink title" to="/list">案例展示</router-link>
          <router-link class="titlelink title" to="/develop">开发设备板</router-link>
          <router-link class="titlelink title" to="/contact">联系我们</router-link>
        </div>
      </div>
    </div>
    <div class="body">
      <div :class="[`nav-list`]" :style="{ display: display }">
        <router-link class="titlelink title" to="/home">首页</router-link>
        <router-link class="titlelink title" to="/product">产品中心</router-link>
        <router-link class="titlelink title" to="/learn">鸿蒙学堂</router-link>
        <router-link class="titlelink title" to="/list">案例展示</router-link>
        <router-link class="titlelink title" to="/develop">开发设备板</router-link>
        <router-link class="titlelink title" to="/contact">联系我们</router-link>
      </div>
      <router-view></router-view>
    </div>
    <div class="footerout">
      <div class="footer">
        <div class="message">
          <div>
            <div class="detail">联系电话</div>
            <div class="detail">0574-88059995</div>
          </div>
          <div>
            <div class="detail">邮箱</div>
            <div class="detail">xiaotuizi0220@126.com</div>
          </div>
          <div>
            <div class="detail">地址</div>
            <div class="detail">浙江省宁波市和邦大厦A座808室</div>
          </div>
        </div>
        <!-- <div class="link">
          <div class="linkimg"></div>
          <div class="textbox">关注软通教育公众号</div>
        </div> -->
        <div class="conact">
          <div class="item">
            <label>留言内容</label>
            <textarea id="licenseText" maxlength="200" v-model="formData.text"></textarea>
          </div>
          <div class="item">
            <label>称呼</label>
            <input class="input" v-model="formData.name" />
          </div>
          <div class="item">
            <label>电话</label>
            <input class="input" v-model="formData.phone" />
          </div>
          <div class="item">
            <label>公司</label>
            <input class="input" v-model="formData.company" />
          </div>
          <div class="sub-botton" @click="submitmessage">提交</div>
        </div>

      </div>
      <div class="buttommsg">
        2021-2023 宁波软通教育科技有限公司版权所有 浙ICP备2023028632号-1
      </div>
    </div>
  </div>
</template>

<script>
import Home from "@/views/home.vue";
import axios from 'axios'
export default {
  data() {
    return {
      clientWidth: "",
      formData: {
        text: '',
        name: '',
        phone: '',
        company: ''
      },
      display: 'none'//移动端点击
    };
  },
  created() {
    this.clientWidth = `${document.documentElement.clientWidth}`;
    console.log(this.clientWidth);
    document.title = '宁波软通教育'
  },
  components: {
    Home,
  },
  methods: {
    simuActive() {
      this.display = this.display == 'none' ? 'flex' : 'none'
      // window.onresize = res => {
      //   console.log(res);
      // };
    },
    submitmessage() {
      if (this.formData.text == '' || this.formData.name == '' || this.formData.phone == '' || this.formData.company == '') {
        this.$message.error('所填数据不能为空')
      } else {
        this.$confirm('确定提交?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.post('http://121.41.26.102:8090/leaveMessage/add', this.formData).then(res => {
            if (res.data.code == 200) {
              this.$message('提交成功');
              this.formData = {}
            }
          })
        }).catch(() => {
        });
      }

    }
  }
};
</script>

<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
}

@media screen and (min-width:768px) {
  .container {

    min-width: 820px;
  }

  .footer {
    min-width: 820px;
    width: 1000px;
  }

  .phone,
  .nav-list,
  .phone-footerout {
    display: none !important;
  }

  .titlelink:hover {
    background-color: #3777ff;
  }
}

.container {
  width: 100%;

  /* height: 100vh; */
  margin: 0.1px;
  padding: 0.1px;

  .tabtopout {
    height: 80px;
    background-color: #121042;
    width: 100%;

    .tabtop {
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 80px;
      margin: 0 auto;
      background-color: #121042;
      display: flex;

      .logo {
        margin-left: 20px;
        width: 20%;

        img {
          height: 40px;
          width: auto;
          margin-bottom: 10px;
        }
      }

      .titlecontainer {
        width: 80%;
        height: 80px;
        margin: 0 auto;

        .title {
          display: inline-block;
          width: 16%;
          color: #e6e6e6;
          font-size: 18px;
          text-align: center;
          height: 80px;
          font-weight: 800;
          line-height: 80px;
          text-decoration: none;
        }
      }
    }
  }


  .body {
    margin: 0;
    padding: 0;
    height: 100%;
    // min-width: 820px;
    width: 100vw !important;

    a:link {
      color: #e6e6e6;
      text-decoration: none;
    }

    .titlelink:hover {
      background-color: #3777ff;
    }
  }

  .footerout {
    height: 400px;
    background-color: #19243a;
    width: 100%;

    // min-width: 820px;
    .footer {
      justify-content: space-between;
      align-items: center;
      // min-width: 820px;
      // width: 1000px;
      height: 250px;
      background-color: #19243a;
      display: flex;
      margin: 0 auto;
      border-bottom: 2px solid #27354f;

      .message {
        width: 300px;
        height: 160px;
        justify-content: center;
        display: flex;
        flex-direction: column;

        div {
          display: flex;
        }

        .detail {
          margin-left: 20px;
          height: 40px;
          line-height: 40px;
          width: 140px;
          color: #e6e6e6;
          flex-wrap: wrap;
          font-size: 12px !important;
          white-space: nowrap;
        }
      }

      .link {
        width: 150px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .linkimg {
          width: 120px;
          height: 120px;
          background-color: #fff;
        }

        .textbox {
          margin-top: 10px;
          width: 120px;
          height: 20px;
          color: #e6e6e6;
          font-size: 13px;
        }
      }

    }

    .conact {
      margin: 10px 0;
      width: 400px;
      height: 200px;
      color: white;

      .item {
        margin: 12px 0;
        display: flex;
        align-items: center;

        label {
          width: 80px;
        }

        textarea {
          width: 200px;
          height: 50px;
        }

        input {
          width: 200px;
        }
      }

      .sub-botton {
        width: 80px;
        height: 30px;
        color: white;
        background-color: #505064;
        border-radius: 6px;
        text-align: center;
        line-height: 30px;
        margin-left: 220px;
      }

      .sub-botton:hover {
        cursor: pointer;
      }
    }

    .buttommsg {
      /* width: 100%; */
      height: 150px;
      line-height: 150px;
      text-align: center;
      font-size: 12px;
      color: #313a4b;
    }
  }
}



@media screen and (max-width:768px) {
  .container {
    width: 100vw;

    // height: 100vh;
    .titlecontainer {
      display: none;
    }

    .tabtopout {
      height: 10vh;
      background-color: #121042;
      width: 100vw !important;

      .phone {
        display: block;

        .menu {
          position: absolute;
          left: 1vh;
          top: 25%;
          color: white;
          font-weight: 800;
          height: 4vh;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;

          div {
            height: 1vh;
            width: 4vh;
            border-bottom: 0.2vh solid white;
          }
        }
      }

      .tabtop {
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 10vh;
        margin: 0 auto;
        background-color: #121042;
        display: flex;
        position: relative;

        .logo {
          width: 100vw;
          display: flex;
          justify-content: center;

          img {
            height: auto;
            width: 30vw;
          }
        }
      }
    }

    .body {
      margin: 0;
      padding: 0;
      // height: 100vh;
      // object-fit: contain !important;
      width: 100vw !important;
      position: relative;

      .nav-list {
        position: absolute;
        top: 0;
        display: flex;
        z-index: 999;
        flex-direction: column;
        background-color: #000;

        .title {
          display: inline-block;
          width: 30vw;
          color: #e6e6e6;
          font-size: 0.5rem !important;
          text-align: center;
          height: 8vh;
          font-weight: 600;
          line-height: 8vh;
          text-decoration: none;
        }
      }
    }

    .footerout {
      width: 100vw !important;
      height: 50vh !important;
      background-color: #19243a;

      .footer {
        width: 100vw !important;
        height: 50vh !important;
        background-color: #19243a;
        display: flex;
        flex-direction: column;
        // border-bottom: 0.5vh solid #27354f;
        align-items: start;

        .message {
          width: 80vw;
          height: 20vh;
          /* justify-content: center; */
          display: flex;
          flex-direction: column;

          div {
            display: flex;
          }

          .detail {
            width: 20vw !important;
            color: #e6e6e6;
            font-size: 0.5rem !important;
            white-space: nowrap;
          }
        }

        .conact {
          margin: 20px;
          width: 80vw !important;
          height: 40vh !important;
          color: white;
          position: relative;

          .sub-botton {
            width: 20vw !important;
            height: 3vh !important;
            color: white;
            background-color: #505064;
            border-radius: 6vh;
            text-align: center;
            line-height: 3vh;
            position: absolute;
            left: 0;
          }

          .item {
            margin: 1vh 0;
            display: flex;
            align-items: center;
            font-size: 0.5rem;

            label {
              width: 30vw;
            }

            textarea {
              width: 50vw;
              height: 10vh;
            }

            input {
              width: 50vw;
            }
          }
        }

        // .link {
        //   width: 150px;
        //   height: 100%;
        //   display: flex;
        //   flex-direction: column;
        //   justify-content: center;
        //   align-items: center;

        //   .linkimg {
        //     width: 120px;
        //     height: 120px;
        //     background-color: #fff;

        //     .textbox {
        //       margin-top: 10px;
        //       width: 120px;
        //       height: 20px;
        //       color: #e6e6e6;
        //       font-size: 13px;
        //     }
        //   }

        // }


      }

      .buttommsg {
        line-height: 10vh !important;
        width: 100vw !important;
        height: 30vh !important;
        text-align: center;
        font-size: 0.8rem;
        color: #313a4b;
        background-color: #19243a;
        padding: 0 5vw;
        box-sizing: border-box;
      }

    }
  }











}
</style>