<template>
  <div id="app">
    <Index />
  </div>
</template>

<script>
import Index from './views/index'
export default {
  name: 'App',
  components: {
   Index
  }
}
</script>

<style>
#app{
}
</style>
