<template>
  <div class="body">
    <div class="img1"><img src="../assets/images/home1.jpg" /></div>
    <div class="img1"><img src="../assets/images/home2.png" /></div>
    <div class="img1"><img src="../assets/images/home3.jpg" /></div>
    <div class="img1"><img src="../assets/images/home4.jpg" /></div>
    <div class="img1"><img src="../assets/images/home5.jpg" /></div>
    <div class="img1"><img src="../assets/images/home6.jpg" /></div>
  </div>
</template>

<script>
export default {
  name: "Home"
};
</script>

<style scoped lang="less">
@media screen and (min-width:768px) {
  .body {
    height: 100%;
    width: 100%;


    .img1 {
      width: 100%;
      height: auto;
      min-width: 1000px;

      /* font-size:0 */
      img {
        width: 100%;
        min-width: 1000px;
        height: auto;
        display: block;
        vertical-align: middle;
      }
    }
  }
}




@media screen and (max-width:768px) {
  .body {
    .img1 {
      width: 100vw !important;
      height: 100% !important;
      img {
        width: 100% !important;
        height: 100% !important;
        display: block;
        // vertical-align: middle;
      }
    }
  }
}
</style>