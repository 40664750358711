<template>
  <div class="body">
    <div class="img1">
      <img src="../assets/images/develop1.png">
      <img src="../assets/images/develop2.png">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Develop'
}
</script>

<style scoped lang="less">
@media screen and (max-width:768px) {
  .img1 {
    width: 100vw !important;
    height: 100% !important;

    img {
      width: 100% !important;
      height: 100% !important;
      display: block;
    }
  }
}

@media screen and (min-width:768px) {
  .img1 img {
    display: block;
    width: 100%;
    height: auto;
    min-width: 1200px !important;
  }
}
</style>